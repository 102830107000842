<template>
  <div class="app-container">
    <CrudTable entity="Product" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';

export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'name',
        {
          field: 'snelStartID',
          label: 'common.itemNumber'
        },
        'shortDescription',
        {
          field: 'productCategoryName',
          filter: {
            type: 'select',
            options: this.productCategories,
            valueField: 'name',
            labelField: 'name'
          }
        },
        {
          field: 'productRegistrationGroupName',
          filter: {
            type: 'select',
            options: this.productRegistrationGroups,
            valueField: 'name',
            labelField: 'name'
          }
        },
        {
          field: 'publishToWebshopYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  },
  computed: {
    productCategories() {
      return this.$store.getters['productCategory/loadedItems'];
    },
    productRegistrationGroups() {
      return this.$store.getters['productRegistrationGroup/loadedItems'];
    }
  },
  watch: {
    productCategories(productCategories) {
      this.getColumn('productCategoryName').filter.options = productCategories;
    },
    productRegistrationGroups(productRegistrationGroups) {
      this.getColumn('productRegistrationGroupName').filter.options = productRegistrationGroups;
    }
  },
  created() {
    this.$store.dispatch('productCategory/getItems');
    this.$store.dispatch('productRegistrationGroup/getItems');
  },
  methods: {
    getColumn(fieldName) {
      return this.columns.find(c => c.field === fieldName);
    }
  }
};
</script>

<style scoped></style>

export const booleanColumnFilter = vue => ({
  type: 'select',
  options: [
    { value: 1, label: vue.$i18n.t('common.yes') },
    { value: 0, label: vue.$i18n.t('common.no') }
  ],
  valueField: 'value',
  labelField: 'label'
});
export const booleanColumnFormatter = vue => (row, column, cellValue) => {
  return cellValue ? vue.$i18n.t('common.yes') : vue.$i18n.t('common.no');
};
